import {useEffect, useState} from 'react';
import {useSelector, useTimer} from 'utilities/hooks';

interface Props {
  paragraphs: string[];
  reveal?: string | JSX.Element;
  onCompleted?: () => void;
  instant?: boolean;
}

const Say = ({paragraphs, reveal, onCompleted, instant = false}: Props) => {
  const isStaging =
    process.env.REACT_APP_API_BASE_URL === 'https://staging.mindletic.com';
  const deliverInstantly = isStaging ? true : instant;

  const darkTheme = useSelector((s) => s.presenter.isFocused);

  const [display, setDisplay] = useState(['']);
  const [done, setDone] = useState(deliverInstantly);
  const [showReveal, setShowReveal] = useState(deliverInstantly);
  const {setTimeout} = useTimer();

  const typeText = async (paragraphs: string[]) => {
    let text: string[] = [];
    for (let i = 0; i < paragraphs.length; i++) {
      for (let j = 0; j <= paragraphs[i].length; j++) {
        text[i] = paragraphs[i].slice(0, j);
        await new Promise<void>((resolve) => setTimeout(resolve, 36));
        setDisplay([...text]);
      }
      await new Promise<void>((resolve) => setTimeout(resolve, 1200));
    }
    setDone(true);
    await new Promise<void>((resolve) => setTimeout(resolve, 1200));
    if (reveal) {
      setShowReveal(true);
      await new Promise<void>((resolve) => setTimeout(resolve, 3000));
    }
    onCompleted?.();
  };

  useEffect(() => {
    if (deliverInstantly) {
      setDisplay(paragraphs);
    } else {
      typeText(paragraphs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (paragraphs.length === 0) {
    return null;
  }

  return (
    <div>
      {display.map((d, i) => {
        const scale =
          done || (i + 1 < display.length && d.length === paragraphs[i].length);
        return (
          <div
            key={i}
            className={`flex items-center ${
              scale && 'translate-x-[15%]'
            } transition-all duration-1000`}
          >
            <div
              className={`p-1.5 mr-4 rounded-full ${scale && 'bg-secondary'}`}
            />
            <h3
              className={`${scale && 'scale-[.65]'} ${
                darkTheme ? 'text-white' : ''
              } transition-all duration-1000 origin-left leading-snug`}
            >
              {d}
            </h3>
          </div>
        );
      })}
      {showReveal && reveal && (
        <h2 className="text-center mt-12 animate-fadeIn">{reveal}</h2>
      )}
    </div>
  );
};

export default Say;
