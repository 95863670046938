import {useEffect} from 'react';
import {MdClose} from 'react-icons/md';
import {onSnapshot} from 'firebase/firestore';

import {useDispatch, useSelector} from 'utilities/hooks';
import AnonymousHat from './components/AnonymousHat';
import Choice from './components/Choice';
import GuessWho from './components/GuessWho';
import ThoughtShare from './components/ThoughtShare';
import QuestionDeck from './components/QuestionDeck';
import Review from './components/Review';
import IdleScreen from './components/IdleScreen';
import Footer from 'Presenter/components/Footer';
import EnterPinForm from './components/EnterPinForm';
import PresentingInProgress from './components/PresentingInProgress';
import IntimacyReview from './components/IntimacyReview';

const Participant = () => {
  const dispatch = useDispatch().participant;
  const showEnterPin = useSelector((s) => s.participant.showEnterPin);
  const activityPart = useSelector((s) => s.participant.activityPart);
  const isIdle = useSelector((s) => s.participant.isIdle);
  const isPresenting = useSelector((s) => s.presenter.isPresenting);
  const darkTheme = useSelector((s) => s.participant.isIdle);
  const firestoreQuery = useSelector((s) => s.participant.firestoreQuery);
  const hasQuery = firestoreQuery !== undefined;
  useEffect(() => {
    return (
      firestoreQuery &&
      onSnapshot(
        firestoreQuery,
        dispatch.handleNewSnapshot,
        dispatch.clearState,
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, hasQuery]);



  return (
    <div
      className={`${isIdle ? 'bg-dark backgroundDark' : 'bg-lighter background'}
        min-h-screen h-fit tablet:h-screen bg-contain bg-no-repeat bg-right-bottom
      `}
    >
      <div className="relevant p-8">
        <div className="flex items-center justify-center tablet:inline">
          {isPresenting ? (
            <PresentingInProgress />
          ) : showEnterPin ? (
            <EnterPinForm />
          ) : activityPart?.step === undefined ? (
            <IdleScreen />
          ) : activityPart.layout === 'anonymous_hat' ? (
            <AnonymousHat part={activityPart} />
          ) : activityPart.layout === 'choice' ? (
            <Choice key={activityPart.key} part={activityPart} />
          ) : activityPart.layout === 'guess_who' ? (
            <GuessWho key={activityPart.key} part={activityPart} />
          ) : activityPart.layout === 'thought_share' ? (
            <ThoughtShare key={activityPart.key} part={activityPart} />
          ) : activityPart.layout === 'question_deck' ? (
            <QuestionDeck part={activityPart} />
          ) : activityPart.layout === 'review' ? (
            <Review key={activityPart.key} part={activityPart} />
          ) : activityPart.layout === 'intimacy_review' ? (
            <IntimacyReview key={activityPart.key} part={activityPart} />
          ) : null}
        </div>

        {!showEnterPin && (
          <div className={'btnTopCornerWrapper'}>
            <MdClose
              className={`w-6 h-6 ${darkTheme ? 'text-white' : 'text-dark'}`}
              onClick={dispatch.clickedCloseButton}
            />
          </div>
        )}
      </div>
      {showEnterPin && (
        <div className="btnBottomWrapper pb-2 bg-lighter bg-opacity-80">
          <Footer />
        </div>
      )}
    </div>
  );
};

export default Participant;
