import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Participant from './Participant';
import Presenter from './Presenter';
import {Provider as StoreProvider} from 'react-redux';
import {store} from './store';

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://94462aebc36361c829b993a3699262e2@o4506982963806208.ingest.us.sentry.io/4507186192121856",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  return (
    <StoreProvider store={store}>

      <Router>
        <Routes>
          <Route index element={<Participant />} />
          <Route path="/present/:token" element={<Presenter />} />
        </Routes>
      </Router>
    </StoreProvider>
  );
}

export default App;
